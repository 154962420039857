import React, { useState } from "react";
import logo from "../../../assets/logo.jpg";
import { LiaBarsSolid } from "react-icons/lia";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../../i18n"; 
import i18next from "i18next";
import { IoIosArrowDown } from "react-icons/io";
import english from "../../../assets/english.png";
import french from "../../../assets/french.png";


const changeLang = (l) => {
  return () => {
    i18next.changeLanguage(l);
    localStorage.setItem('l', l);
  }
}


function Navbar() {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [langDropdownOpen, setLangDropdownOpen] = useState(false);
  const [currentLang, setCurrentLang] = useState("en"); 

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleLangDropdown = () => {
    setLangDropdownOpen(true);
  };

  const handleLangDropdownLeave = () => {
    setLangDropdownOpen(false);
  };

  const handleLangChange = (lang) => {
    setCurrentLang(lang);
    changeLang(lang)();
  };

  return (
    <>
      <div className="flex relative justify-between items-center w-full bg-white h-20 md:h-24 xl:px-40 lg:px-20 md:px-14 xxxs:px-10">
        <div>
          <img src={logo} alt="Logo" className="cursor-pointer z-10 absolute top-1 lg:rounded-2xl xl:w-80 lg:w-44 md:w-36 sm:w-32 xxs:w-24 xxxs:w-20" />
        </div>
        <div className="hidden lg:flex gap-6 font-abc font-medium text-md text-sky-950 cursor-pointer">
          <ul className="flex gap-6">
            <li className="hover:text-sky-800"><Link to="/Index1">{ t('nav_1') }</Link></li>
            <li className="hover:text-sky-800"><Link to="/Index2">Solution</Link></li>
            <li className="hover:text-sky-800"><Link to="/Index3">Certifications</Link></li>
            <li className="hover:text-sky-800"><Link to="/Index4">Contact</Link></li>
            <li className="hover:text-sky-800 relative z-10" onMouseEnter={handleLangDropdown} >
              <div className="flex items-center gap-2" >
                {currentLang === "en" ?  <img src={english} alt="" /> : <img src={french} alt="" /> } Current Languages <IoIosArrowDown />
              </div>
              {langDropdownOpen && (
                <ul className="absolute top-8 left-0 bg-neutral-100 shadow-lg px-14 py-3" onClick={handleLangDropdownLeave}  onMouseLeave={handleLangDropdownLeave}>
                  <li className="hover:text-gray-400 text-lg flex items-center gap-2 " onClick={() => handleLangChange("en")}> <img src={english} alt="" /> English</li>
                  <li className="hover:text-gray-400 text-lg mt-3 flex items-center gap-2" onClick={() => handleLangChange("fr")}><img src={french} alt="" /> française</li>
                </ul>
              )}
            </li>
          </ul>
        </div>
        <div className="flex lg:hidden">
          <LiaBarsSolid size={25} onClick={toggleDropdown} className="text-2xl cursor-pointer text-sky-950" />
        </div>
      </div>
      {isOpen && (
  <div className="lg:hidden flex flex-col items-left md:px-28 sm:px-16 xxs:px-14 xxxs:px-12 m-10  bg-white shadow-lg p-5 " onClick={toggleDropdown}>
    <ul className="flex flex-col gap-4 font-abc font-medium  text-sky-950 cursor-pointer">
      <li><Link to="/Index1">{ t('nav_1') }</Link></li>
      <li><Link to="/Index2">Solution</Link></li>
      <li><Link to="/Index3">Certifications</Link></li>
      <li><Link to="/Index4">Contact</Link></li>
      <li className="hover:text-gray-400 relative pb-32">
        <div className="flex items-center gap-2  md:text-lg xxxs:text-xs sm:text-lg xxs:text-sm  xxxs:font-medium " >
          {currentLang === "en"? <img src={english} alt="" /> : <img src={french} alt="" />} Current Languages 
        </div>
       
          <ul className="absolute top-8 left-0 bg-white shadow-lg  px-8 py-2 z-10 ">
            <li className="hover:text-gray-400  flex items-center gap-2" onClick={() => { handleLangChange("en"); setLangDropdownOpen(false); }}><img src={english} alt="" /> English</li>
            <li className="hover:text-gray-400  flex items-center gap-2 mt-3" onClick={() => { handleLangChange("fr"); setLangDropdownOpen(false); }}><img src={french} alt="" /> française</li>
          </ul>
        
      </li>
    </ul>
  </div>
)}
    </>
  );
}

export default Navbar;