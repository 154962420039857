import './App.css';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import Index from './pages/main/Index';
import Navbar from './components/common/Navbar/Navbar';
import Footer from './components/common/Footer/Footer';
import Index1 from './pages/Whatwedo/Index1';
import Index2 from './pages/Solution/Index2';
import Index3 from './pages/Certifications/Index3';
import Index4 from './pages/Contact/Index4';
import Bottem from './Bottem';


function App() {
  return (
    <>
        <BrowserRouter>
            <Navbar />
            <Bottem />
          <Routes>
            <Route path="/" element={<Index />} />
            <Route path="/Index1" element={<Index1 />} />
            <Route path="/Index2" element={<Index2 />} />
            <Route path="/Index3" element={<Index3 />} />
            <Route path="/Index4" element={<Index4 />} />
          </Routes>
          <Footer />
        </BrowserRouter>
    </>
  );
}

export default App;
