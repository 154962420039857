import React from 'react';

const FeatureCard = ({ title, content }) => {
    return (
        <div className="lg:w-[33%] xxxs:w-[100%] text-zinc-800">
            <h1 className="text-2xl font-medium">{title}</h1>
            <p className="text-justify mt-10 text-sm tracking-wider">
                {content}
            </p>
        </div>
    );
};

export default FeatureCard;
