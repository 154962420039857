import React from "react";
import education from "../../assets/education.png";
import setting from "../../assets/setting.png";
import love from "../../assets/love.png";
import Section1 from "./Section1";
import { useTranslation } from "react-i18next";
import "../../i18n"; 





function Index() {

    const { t } = useTranslation();

    return ( 
        <>
           <div data-aos="zoom-in" className="main w-full pb-24">
                <div className="font-abc text-sky-950 xl:ml-40 lg:ml-20 md:ml-20 sm:ml-12 xxs:ml-10 xxxs:ml-7">
                    <h1 data-aos="slide-down" className="italic lg:text-7xl md:text-5xl sm:text-4xl xxs:text-3xl xxxs:text-3xl font-medium  text-shadow-double 2xl:pt-28 xl:pt-80 lg:pt-60 xxs:pt-28 xxxs:pt-20">{ t('title_main') }</h1>
                    <h1 className="mt-10 sm:text-xl xxs:text-lg xxs:font-medium xxxs:font-bold xxxs:text-xl  xxs:w-[80%] xxxs:w-[70%]">{ t('title_des_1') }</h1>
                    <h1 className="mt-10 md:text-md xxs:text-sm  md:w-[40%] sm:w-[45%] xxxs:w-[45%] font-xyz sm:leading-normal xxs:leading-relaxed">{ t('title_des_2') }</h1>
                </div>
                <div className="mt-10  xl:ml-40 lg:ml-20 md:ml-20 xs:ml-12 xxs:ml-10 xxxs:ml-7">
                    <div className="text-sky-950 font-abc">
                        <div className="flex gap-3 items-center md:text-2xl xs:text-xl xxs:text-sm xxxs:text-xl">
                            <img src={education} alt="" />
                            <h1 className="text-shadow-single font-medium">{ t('part1') }</h1>
                        </div>
                        <div className="flex gap-3 items-center md:text-2xl xs:text-xl lg:mt-10 md:mt-5 xxs:text-sm xxxs:text-xl">
                            <img src={setting} alt="" />
                            <h1 className="text-shadow-single font-medium">{ t('part2') }</h1>
                        </div>
                        <div className="flex gap-3 items-center md:text-2xl xs:text-xl lg:mt-10 md:mt-5 xxs:text-sm xxxs:text-xl">
                            <img src={love} alt="" />
                            <h1 className="text-shadow-single font-medium">{ t('part3') }</h1>
                        </div>
                    </div>
                </div>
           </div>
           <div className="font-pqr   bg-cyan-900 text-white text-center py-6 mb-24">
                <h1 className="md:text-2xl sm:text-xl xxs:text-xl xxxs:text-2xl font-bold">{ t('main_center_part') }</h1>
            </div>
            <Section1 />
        </>
     );
}

export default Index;