import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslations from './Json/en/transaltion.json';
import frTranslations from './Json/fr/transaltion.json';

const resources = {
  en: { translation: enTranslations },
  fr: { translation: frTranslations },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem('l') || 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
