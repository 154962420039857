import React from "react";
import section1 from "../../assets/section1.jpg";
import Card from "../../components/common/main/section1/Card";
import { useTranslation } from "react-i18next";
import "../../i18n"; 
import { useNavigate } from "react-router-dom";


function Section1() {

    const navigate = useNavigate();

    const contactus = () => {
        navigate('/Index4');
    };

    const { t } = useTranslation();

    return ( 
        <>
            <div className="relative xl:px-40 lg:px-28 md:px-20 sm:px-12 xxs:px-9 xxxs:px-8">
                <h1 className="font-abc font-medium text-sm leading-6 text-stone-500 text-justify mb-16">{ t('main_des_1') }</h1>
                <div className="flex lg:gap-16 md:gap-6 lg:flex-nowrap xxxs:flex-wrap">
                    <div className="lg:w-[54%] md:w-[100%]">
                        <img src={section1} alt="" />
                    </div>
                    <div className="lg:w-[23%] md:w-[48%]  font-abc font-medium text-sm leading-6 text-stone-500 text-justify md:mt-0 xxxs:mt-8">
                        <h1>
                        { t('main_des_part_1/1') }
                        </h1>
                        <h1 className="mt-3">
                        { t('main_des_part_1/2') }
                            
                        </h1>
                    </div>
                    <div className="lg:w-[23%] md:w-[48%] font-abc font-medium text-sm leading-6 text-stone-500 text-justify">
                        <h1>
                        { t('main_des_part_2/1') }
                        </h1>
                        <h1 className="mt-3">
                        { t('main_des_part_2/2') }
                        </h1>
                    </div>
                </div>
            </div>

            <div className="font-pqr text-white bg-amber-600 text-center py-6 mt-24">
                    <h1 className="lg:text-3xl md:text-2xl sm:text-lg xxs:text-xl xxxs:text-2xl font-bold">{ t('main_center_part_1') }</h1>
            </div>

            <div className="flex mt-24 gap-16 xl:px-40 lg:px-28 md:px-20 sm:px-12 xxs:px-9 xxxs:px-8 lg:flex-nowrap xxxs:flex-wrap">
                <Card
                    number="01"
                    title={ t('main_common_part_1_title') }
                    description={ t('main_common_part_1_des') }
                />
                <Card
                    number="02"
                    title={ t('main_common_part_2_title') }
                    description={ t('main_common_part_2_des') }
                />
                <Card
                    number="03"
                    title={ t('main_common_part_3_title') }
                    description={ t('main_common_part_3_des') }
                />
            </div>


            <div className="mt-40 xl:px-40 lg:px-28 md:px-20 xs:px-12 xxs:px-9 xxxs:px-8">
                <div className="border-2 md:px-16 sm:px-8 xxs:px-9 xxxs:px-8 ">
                    <h1 className="lg:text-5xl md:text-3xl sm:text-xl  xxxs:text-2xl md:font-normal sm:font-medium font-def mt-14 xl:w-[80%]  xl:leading-tight">{ t('main_box_main_title') }</h1>
                    <p className="lg:text-lg md:text-sm xxxs:text-sm xxxs:leading-relaxed font-lmn mt-5 text-zinc-700 leading-7 font-medium ">{ t('main_box_main_des') }</p>
                    <button className="mt-5 mb-12 bg-sky-900 rounded-3xl text-white px-6 py-3 font-bold text-md" onClick={contactus}>{ t('main_box_button') }</button>
                </div>
            </div>

            
        </>
     );
}

export default Section1;