import React from 'react';

const Section = ({ title1, content1, title2, content2, title3, content3, title4, content4, title5, content5, title6, content6 }) => {
    return (
        <div className="lg:w-[50%] xxxs:w-[100%] text-slate-500">
            <h1 className="font-bold">{title1}</h1>
            <p className="text-justify mt-1 mb-5 text-sm leading-6 tracking-wide">{content1}</p>
            <h1 className="font-bold">{title2}</h1>
            <p className="text-justify mt-1 mb-5 text-sm leading-6 tracking-wide">{content2}</p>
            <h1 className="font-bold">{title3}</h1>
            <p className="text-justify mt-1 mb-5 text-sm leading-6 tracking-wide">{content3}</p>
            <h1 className="font-bold">{title4}</h1>
            <p className="text-justify mt-1 mb-5 text-sm leading-6 tracking-wide">{content4}</p>
            <h1 className="font-bold">{title5}</h1>
            <p className="text-justify mt-1 mb-5 text-sm leading-6 tracking-wide">{content5}</p>
            <h1 className="font-bold">{title6}</h1>
            <p className="text-justify mt-1 mb-5 text-sm leading-6 tracking-wide">{content6}</p>
        </div>
    );
};

export default Section;
