import React from "react";
import bar from "../../assets/bar.png";
import share from "../../assets/share.png";
import database from "../../assets/database.png";
import Section1 from "./Section1";
import { useTranslation } from "react-i18next";
import "../../i18n"; 

function Index3() {

    const { t } = useTranslation();

    return ( 
        <>
            <div data-aos="zoom-in" className="index3 w-full 2xl:h-[70vh] pb-24">
                <div className="font-xyz text-white  xl:pt-24 xl:ml-40  md:ml-20 sm:ml-12 xxs:ml-10 xxxs:ml-8">
                        <h1 data-aos="slide-down" className="italic font-medium text-shadow-five lg:text-7xl md:text-5xl xxxs:text-4xl  2xl:pt-0 lg:pt-20 sm:pt-12 xxs:pt-10 xxxs:pt-14">{ t('title3') }</h1>
                </div>
                <div className="mt-10 xl:ml-40 md:ml-20 sm:ml-12  xxs:ml-10 xxxs:ml-8">
                    <div className="text-white">
                        <div className="flex gap-3 xl:items-center xxxs:items-start text-3xl 2xl:tracking-normal xxxs:tracking-wider">
                            <img src={bar} alt="" />
                            <h1 className=" text-shadow-six xl:leading-none xl:w-[100%] xxxs:w-[15%] lg:leading-10 ">{ t('title_part_1') }</h1>
                        </div>
                        <div className="flex gap-4 xl:items-center xxxs:items-start text-3xl mt-5 2xl:tracking-normal xxxs:tracking-wider">
                            <img src={share} alt="" />
                            <h1 className=" text-shadow-six xl:leading-none xl:w-[100%] xxxs:w-[15%] lg:leading-10">{ t('title_part_2') }</h1>
                        </div>
                        <div className="flex gap-5 xl:items-center xxxs:items-start text-3xl mt-5 2xl:tracking-normal xxxs:tracking-wider">
                            <img src={database} alt="" />
                            <h1 className=" text-shadow-six xl:leading-none xl:w-[100%] xxxs:w-[15%] lg:leading-10">{ t('title_part_3') }</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="font-xyz bg-cyan-900 text-white text-center py-5 mb-24">
                    <h1 className="lg:text-xl md:text-lg xxxs:text-2xl xxs:px-0 xxxs:px-4">{ t('center_part') }</h1>
            </div>
            <Section1 />
        </>
     );
}

export default Index3;