import React, { useState } from "react";
import english from "../src/assets/english.png";
import french from "../src/assets/french.png";
import "./i18n";
import i18next from "i18next";
import { ImCross } from "react-icons/im";
import img from "./assets/logo.jpg"

const changeLang = (l) => {
  return () => {
    i18next.changeLanguage(l);
    localStorage.setItem('l', l);
  }
}

function Bottem() {
  const [showManageConsent, setShowManageConsent] = useState(false);

  const [showHelloBox, setShowHelloBox] = useState(false);

  const [currentLang, setCurrentLang] = useState("en"); 


  
  const handleLangChange = (lang) => {
    setCurrentLang(lang);
    changeLang(lang)();
  };



  return (
    <>
      <div className="fixed bottom-0 md:right-36 xxs:right-10 xxxs:right-5 px-6 py-6 rounded-t-lg shadow-lg shadow-black bg-white group z-20">
        <div className="mb-7 cursor-pointer hidden group-hover:block" onClick={() => handleLangChange("en")}>
          <img src={english} alt="French" />
        </div>
        <div className="mb-7 cursor-pointer hidden group-hover:block" onClick={() => handleLangChange("fr")}>
            <img src={french} alt="English" />
        </div>
        <div className=" block cursor-pointer " onClick={() => handleLangChange("en")}>
        {currentLang === "en" ?  <img src={english} alt="" /> : <img src={french} alt="" /> }
        </div>
      </div>
      <div className="fixed bottom-0 right-4 rounded-t-lg shadow-black bg-white group z-10 xl:block xxxs:hidden" onMouseOver={() => setShowManageConsent(true)} onMouseLeave={() => setShowManageConsent(false)}>
          <div className="block px-20 py-2 cursor-pointer">

          </div>
          {showManageConsent && (
            <div className="block text-sm pb-3 ml-7 cursor-pointer text-center" onClick={() => setShowHelloBox(true)}>
              Manage Consent
            </div>
          )}
          {showHelloBox && (
            <div className="fixed bottom-2 left-2 right-2 xl:h-56 lg:h-80 p-5 rounded-xl bg-white shadow-black text-center">
              <div className="flex justify-between ">
                <div className="w-20 cursor-pointer">
                        <img src={img} alt=""/>
                </div>
                <div className="text-xl md:block xxxs:hidden">
                  Cookie
                </div>
                <button className="text-gray-400 hover:text-gray-600 transition duration-300"onClick={() => setShowHelloBox(false)}>
                  <ImCross />
                </button>
              </div>
              <div className="flex lg:flex-nowrap xxxs:flex-wrap cursor-pointer gap-4">
                <div className="xl:w-[50%] lg:w-[30%] xxxs:w-[100%] xl:text-left xxxs:text-justify pt-5">
                    <div className="xl:leading-5 xl:tracking-wide sm:leading-6 lg:tracking-wider  sm:tracking-tight font-abc sm:text-base xxxs:text-sm">
                    To provide the best experiences, we use technologies like cookies to store and/or access device information. Consenting to these technologies will allow us to process data such as browsing behavior or unique IDs on this site. Not consenting or withdrawing consent, may adversely affect certain features and functions.
                    </div>
                </div>
                <div className="xl:w-[45%] lg:w-[65%] xxxs:w-[100%]">
                  <div className="flex justify-between lg:gap-y-0 xxxs:gap-y-6 lg:flex-nowrap  xxxs:flex-wrap items-center mt-5">
                    <div className="bg-slate-500 px-10 text-white py-3 rounded-lg xxxs:w-[100%]">
                        <button>Accept</button>
                    </div>
                    <div className="xxxs:w-[100%]">
                        <button>Deny</button>
                    </div>
                    <div className="xxxs:w-[100%]">
                        <button>View Preferences</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
    </>
  );
}

export default Bottem;
