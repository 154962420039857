import React, { useState } from "react";
import FAQItem from "../../components/common/Whatwedo/Faqitems";
import { useTranslation } from "react-i18next";
import "../../i18n"; 

function Section2() {

  const { t } = useTranslation();

  const [selected, setSelected] = useState(null);

  const faqItems = [
    {
      id: 1,
      title: t("whatwedo_common_title_1"),
      description:
      t("whatwedo_common_des_1")
    },
    {
      id: 2,
      title: t("whatwedo_common_title_2"),
      description:
      t("whatwedo_common_des_2")
    },
    {
        id: 3,
        title:t("whatwedo_common_title_3"),
        description:
        t("whatwedo_common_des_3")
    },
    {
        id: 4,
        title: t("whatwedo_common_title_4"),
        description:
        t("whatwedo_common_des_4")
    },
    {
        id: 5,
        title: t("whatwedo_common_title_5"),
        description:
        t("whatwedo_common_des_5")
    },
    {
        id: 6,
        title: t("whatwedo_common_title_6"),
        description:
        t("whatwedo_common_des_6")
    },
    {
        id: 7,
        title: t("whatwedo_common_title_7"),
        description:
        t("whatwedo_common_des_7")
    },
    {
        id: 8,
        title: t("whatwedo_common_title_8"),
        description:
        t("whatwedo_common_des_8")
    },
    {
        id: 9,
        title:t("whatwedo_common_title_9"),
        description:
        t("whatwedo_common_des_9")
    },
    {
        id: 10,
        title: t("whatwedo_common_title_10"),
        description:
        t("whatwedo_common_des_10")
    },
    {
        id: 11,
        title: t("whatwedo_common_title_11"),
        description:
        t("whatwedo_common_des_11")
    },
    {
        id: 12,
        title: t("whatwedo_common_title_12"),
        description:
        t("whatwedo_common_des_12")
    },
  ];

  const Faq1 = [
    {
        id: 13,
        title:t("whatwedo_common_title_13"),
        description:
        t("whatwedo_common_des_13")
    },
    {
        id: 14,
        title: t("whatwedo_common_title_14"),
        description:
        t("whatwedo_common_des_14")
    },
    {
        id: 15,
        title: t("whatwedo_common_title_15"),
        description:
        t("whatwedo_common_des_15")
    },
    {
        id: 16,
        title:t("whatwedo_common_title_16"),
        description:
        t("whatwedo_common_des_16")
    },
    {
        id: 17,
        title:t("whatwedo_common_title_17"),
        description:
        t("whatwedo_common_des_17")
    },
    {
        id: 18,
        title: t("whatwedo_common_title_18"),
        description:
        t("whatwedo_common_des_18")
    },
    {
        id: 19,
        title:t("whatwedo_common_title_19"),
        description:
        t("whatwedo_common_des_19")
    },
    {
        id: 20,
        title: t("whatwedo_common_title_20"),
        description:
        t("whatwedo_common_des_20")
    },
    {
        id: 21,
        title: t("whatwedo_common_title_21"),
        description:
        t("whatwedo_common_des_21")
    },
    {
        id: 22,
        title: t("whatwedo_common_title_22"),
        description:
        t("whatwedo_common_des_22")
    },
    {
        id: 23,
        title: t("whatwedo_common_title_23"),
        description:
        t("whatwedo_common_des_23")
    },
    {
        id: 24,
        title:t("whatwedo_common_title_24"),
        description:
        t("whatwedo_common_des_24")
    },
    {
        id: 25,
        title: t("whatwedo_common_title_25"),
        description:
        t("whatwedo_common_des_25")
    },
    {
        id: 26,
        title: t("whatwedo_common_title_26"),
        description:
        t("whatwedo_common_des_26")
    },
    {
        id: 27,
        title: t("whatwedo_common_title_27"),
        description:
        t("whatwedo_common_des_27")
    },
  ]


  const Faq2 = [
    {
        id: 28,
        title:t("whatwedo_common_title_28"),
        description:
        t("whatwedo_common_des_28")
    },
    {
        id: 29,
        title:t("whatwedo_common_title_29"),
        description:
        t("whatwedo_common_des_29")
    },
    {
        id: 30,
        title: t("whatwedo_common_title_30"),
        description:
        t("whatwedo_common_des_30")
    },
    {
        id: 31,
        title: t("whatwedo_common_title_31"),
        description:
        t("whatwedo_common_des_31")
    },
  ]


  
  const Faq3 = [
    {
        id: 32,
        title: t("whatwedo_common_title_32"),
        description:
        t("whatwedo_common_des_32")
    },
    {
        id: 33,
        title: t("whatwedo_common_title_33"),
        description:
        t("whatwedo_common_des_33")
      },
      {
        id: 34,
        title: t("whatwedo_common_title_34"),
        description:
        t("whatwedo_common_des_34")
    },
    {
        id: 35,
        title:t("whatwedo_common_title_35"),
        description:
        t("whatwedo_common_des_35")
    },
  ]



  const Faq4 = [
    {
        id: 36,
        title:t("whatwedo_common_title_36"),
        description:
        t("whatwedo_common_des_36")
    },
    {
        id: 37,
        title:t("whatwedo_common_title_37"),
        description:
        t("whatwedo_common_des_37")
    },
    {
        id: 38,
        title: t("whatwedo_common_title_38"),
        description:
        t("whatwedo_common_des_38")
    },
    {
        id: 39,
        title: t("whatwedo_common_title_39"),
        description:
        t("whatwedo_common_des_39")
    },
    {
        id: 40,
        title: t("whatwedo_common_title_40"),
        description:
        t("whatwedo_common_des_40")
    },
    {
        id: 41,
        title: t("whatwedo_common_title_41"),
        description:
        t("whatwedo_common_des_41")
    },
    {
        id: 42,
        title: t("whatwedo_common_title_42"),
        description:
        t("whatwedo_common_des_42")
    },
    {
        id: 43,
        title: t("whatwedo_common_title_43"),
        description:
        t("whatwedo_common_des_43")
    },
  ]



  const Faq5 = [
    {
        id: 44,
        title: t("whatwedo_common_title_44"),
        description:
        t("whatwedo_common_des_44")
    },
    {
        id: 45,
        title:t("whatwedo_common_title_45"),
        description:
        t("whatwedo_common_des_45")
    },
    {
        id: 46,
        title: t("whatwedo_common_title_46"),
        description:
        t("whatwedo_common_des_46")
    },
    {
        id: 47,
        title:t("whatwedo_common_title_47"),
        description:
        t("whatwedo_common_des_47")
    },
    {
        id: 48,
        title: t("whatwedo_common_title_48"),
        description:
        t("whatwedo_common_des_48")
    },
    {
        id: 49,
        title:t("whatwedo_common_title_49"),
        description:
        t("whatwedo_common_des_49")
    },
    {
        id: 50,
        title: t("whatwedo_common_title_50"),
        description:
        t("whatwedo_common_des_50")
    },
    {
        id: 51,
        title: t("whatwedo_common_title_51"),
        description:
        t("whatwedo_common_des_51")
    },
    {
        id: 52,
        title:t("whatwedo_common_title_52"),
        description:
        t("whatwedo_common_des_52")
    },
    {
        id: 53,
        title: t("whatwedo_common_title_53"),
        description:
        t("whatwedo_common_des_53")
    },
    {
        id: 54,
        title: t("whatwedo_common_title_54"),
        description:
        t("whatwedo_common_des_54")
    },
    {
        id: 55,
        title: t("whatwedo_common_title55"),
        description:
        t("whatwedo_common_des_55")
    },
  ]
  

  return (
    <>
      <div className="flex lg:flex-nowrap xxxs:flex-wrap gap-3 font-pqr xl:px-40 lg:px-24 md:px-20 xxs:px-5 xxxs:px-4">
        <div className="lg:w-[50%] md:w-[100%] text-white">
          <h1 className="w-full md:text-3xl sm:text-xl xxs:text-xl bg-sky-900 rounded-xl font-extrabold py-5 px-8">{ t('whatwedo_common_main_title_1') }</h1>
          <div className=" text-black max-w-full  border border-gray-200 rounded-xl mt-3">
            <ul className="shadow-box">
              {faqItems.map((item) => (
                <FAQItem
                  key={item.id}
                  id={item.id}
                  title={item.title}
                  selected={selected}
                  setSelected={setSelected}
                >
                  <p>{item.description}</p>
                </FAQItem>
              ))}
            </ul>
          </div>
          <h1 className="w-full md:text-3xl sm:text-xl bg-sky-900 rounded-xl font-extrabold py-5 px-8 mt-3">{ t('whatwedo_common_main_title_2') }</h1>
          <div className=" text-black max-w-full  border border-gray-200 rounded-xl mt-3">
            <ul className="shadow-box">
              {Faq1.map((item) => (
                <FAQItem
                  key={item.id}
                  id={item.id}
                  title={item.title}
                  selected={selected}
                  setSelected={setSelected}
                >
                  <p>{item.description}</p>
                </FAQItem>
              ))}
            </ul>
          </div>
        </div>

        <div className="lg:w-[50%] md:w-[100%] text-white">
          <h1 className="w-full md:text-3xl sm:text-xl bg-sky-900 rounded-xl font-extrabold py-5 px-8 ">{ t('whatwedo_common_main_title_3') }</h1>
          <div className=" text-black max-w-full  border border-gray-200 rounded-xl mt-3">
            <ul className="shadow-box">
              {Faq2.map((item) => (
                <FAQItem
                  key={item.id}
                  id={item.id}
                  title={item.title}
                  selected={selected}
                  setSelected={setSelected}
                >
                  <p>{item.description}</p>
                </FAQItem>
              ))}
            </ul>
          </div>
          <h1 className="w-full md:text-3xl sm:text-xl bg-sky-900 rounded-xl font-extrabold py-5 px-8 mt-3">{ t('whatwedo_common_main_title_4') }</h1>
          <div className=" text-black max-w-full  border border-gray-200 rounded-xl mt-3">
            <ul className="shadow-box">
              {Faq3.map((item) => (
                <FAQItem
                  key={item.id}
                  id={item.id}
                  title={item.title}
                  selected={selected}
                  setSelected={setSelected}
                >
                  <p>{item.description}</p>
                </FAQItem>
              ))}
            </ul>
          </div>
          <h1 className="w-full md:text-3xl sm:text-xl bg-sky-900 rounded-xl font-extrabold py-5 px-8 mt-3">{ t('whatwedo_common_main_title_5') }</h1>
          <div className=" text-black max-w-full  border border-gray-200 rounded-xl mt-3">
            <ul className="shadow-box">
              {Faq4.map((item) => (
                <FAQItem
                  key={item.id}
                  id={item.id}
                  title={item.title}
                  selected={selected}
                  setSelected={setSelected}
                >
                  <p>{item.description}</p>
                </FAQItem>
              ))}
            </ul>
          </div>
          <h1 className="w-full md:text-3xl sm:text-xl bg-sky-900 rounded-xl font-extrabold py-5 px-8 mt-3">{ t('whatwedo_common_main_title_6') }</h1>
          <div className=" text-black max-w-full  border border-gray-200 rounded-xl mt-3">
            <ul className="shadow-box">
              {Faq5.map((item) => (
                <FAQItem
                  key={item.id}
                  id={item.id}
                  title={item.title}
                  selected={selected}
                  setSelected={setSelected}
                >
                  <p>{item.description}</p>
                </FAQItem>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default Section2;