import React, { useState, useEffect } from 'react';
import Section from '../../components/common/Solution/Section';
import { useTranslation } from "react-i18next";
import "../../i18n"; 

const Section2 = () => {

  const { t } = useTranslation();


  const [digitalCount, setDigitalCount] = useState(0);
  const [analogueCount, setAnalogueCount] = useState(0);
  const digitalTarget = 100000000;
  const analogueTarget = 150000000;

  useEffect(() => {

    const intervalTime = 15; // Interval time in milliseconds
    const increment = 1; // Increment by 1 each interval

    const interval = setInterval(() => {
      setDigitalCount(prev => {
        if (prev < 100) {
          return prev + increment;
        } else {
          clearInterval(interval);
          return 100;
        }
      });

      setAnalogueCount(prev => {
        if (prev < 100) {
          return prev + increment;
        } else {
          clearInterval(interval);
          return 100;
        }
      });
    }, intervalTime);

    const timer = setTimeout(() => {
      setDigitalCount(digitalTarget);
      setAnalogueCount(analogueTarget);
    }, 100 * intervalTime + 500); // Adding a small delay to ensure the transition

    return () => {
      clearInterval(interval);
      clearTimeout(timer);
    };
  }, []);
    return ( 
        <>
           <div className="bg-cyan-900 text-white text-center py-24">
                <h1 className="xl:text-5xl lg:text-4xl md:text-3xl xxs:text-xl xxxs:text-lg font-bold font-def">{ t('solution_common2_title') }</h1>
                <div className="flex lg:flex-nowrap xxxs:flex-wrap mt-20 xl:px-10 lg:px-20 sm:px-10">
                    <div className="lg:w-[50%] xxxs:w-[100%]">
                    <h1 className="xl:text-8xl lg:text-7xl md:text-7xl sm:text-5xl xxxs:text-4xl ">
                        {digitalCount < 100 
                        ? digitalCount 
                        : digitalTarget.toLocaleString('en-US', { useGrouping: true }).replace(/,/g, "'")}
                    </h1>
                    <p className="md:text-xl sm:text-lg lg:mt-4 lg:mb-0 md:mt-6 md:mb-6 sm:mt-8 sm:mb-8 xxs:mt-10 xxs:mb-10 xxxs:mb-12 xxxs:mt-12">
                        { t('solution_common2_des1') }
                    </p>
                    </div>
                    <div className="lg:w-[50%] xxxs:w-[100%]">
                    <h1 className="xl:text-8xl lg:text-7xl  md:text-7xl sm:text-5xl xxxs:text-4xl">
                        {analogueCount < 100 
                        ? analogueCount 
                        : analogueTarget.toLocaleString('en-US', { useGrouping: true }).replace(/,/g, "'")}
                    </h1>
                    <p className="md:text-xl sm:text-lg lg:mt-4 md:mt-6 sm:mt-8 xxs:mt-10 xxxs:mt-12">
                    { t('solution_common2_des2') }
                    </p>
                    </div>
                </div>
            </div>


            <div className="lg:px-36 md:px-20 sm:px-10 xxxs:px-8  mt-24 flex lg:flex-nowrap xxxs:flex-wrap gap-8 font-abc">
            <Section
                title1={ t('solution_common3_title_1') }
                content1={ t('solution_common3_des_1') }
                title2={ t('solution_common3_title_2') }
                content2={ t('solution_common3_des_2') }
                title3={ t('solution_common3_title_3') }
                content3={ t('solution_common3_des_3') }
                title4={ t('solution_common3_title_4') }
                content4={ t('solution_common3_des_4') }
                title5={ t('solution_common3_title_5') }
                content5={ t('solution_common3_des_5') }
                title6={ t('solution_common3_title_6') }
                content6={ t('solution_common3_des_6') }
            />
            <Section
                title1={ t('solution_common3_title_7') }
                content1={ t('solution_common3_des_7') }
                title2={ t('solution_common3_title_8') }
                content2={ t('solution_common3_des_8') }
                title3={ t('solution_common3_title_9') }
                content3={ t('solution_common3_des_9') }
                title4={ t('solution_common3_title_10') }
                content4={ t('solution_common3_des_10') }
                title5={ t('solution_common3_title_11') }
                content5={ t('solution_common3_des_11') }
                title6={ t('solution_common3_title_12') }
                content6={ t('solution_common3_des_12') }
            />
        </div>
        </>
     );
}

export default Section2;