import React from "react";
import footer from "../../../assets/footer.png";
import { FaIdCard, FaLinkedin, FaTwitter, FaYoutube } from "react-icons/fa";
import { RiFacebookCircleFill } from "react-icons/ri";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";
import { IoCallSharp } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import "../../../i18n"; 


function Footer() {

    const { t } = useTranslation();

    return ( 
        <>
            <div className="bg-sky-950 mt-24 pb-10 ">
                <div className="flex lg:px-40 md:px-20 sm:px-12 xxs:px-4 xxxs:px-5 lg:flex-nowrap xxxs:flex-wrap text-zinc-400 lg:gap-24 xxxs:gap-10">
        
                    <div className="lg:w-[40%] xxxs:w-[100%]  mt-10">
                        <img src={footer} alt="" className="lg:w-48 md:w-80 sm:w-[120%] xxs:w-[100%] xxxs:w-[100%]"/>
                    </div>

                    <div className="lg:w-[20%] xxxs:w-[100%] lg:mt-10 ">   
                            <h1 className="text-lg font-medium">{ t('footer_sec_1') }</h1>
                            <div className="flex items-center gap-5 mt-3">
                                <FaLinkedin size={24}/> <h1>LinkedIn</h1>
                            </div>
                            <div className="flex items-center gap-5 mt-3">
                                <RiFacebookCircleFill size={24}/> <h1>Facebook</h1>
                            </div>
                            <div className="flex items-center gap-5 mt-3">
                                <FaYoutube size={24}/> <h1>YouTube</h1>
                            </div>
                            <div className="flex items-center gap-5 mt-3">
                                <FaTwitter size={24}/> <h1>Twitter</h1>
                            </div>
                            <div className="flex items-center gap-5 mt-3">
                                <IoLogoWhatsapp size={24}/> <h1>WhatsApp</h1>
                            </div>
                    </div>

                    <div className="lg:w-[20%] xxxs:w-[100%] lg:mt-10">
                        <h1 className="text-lg font-medium">{ t('footer_sec_2') }</h1>
                        <p className="mt-3 text-lg">{ t('footer_part_1') }</p>
                        <p className="mt-3 text-lg">{ t('footer_part_2') }</p>
                        <p className="mt-3 text-lg">{ t('footer_part_3') }</p>
                    </div>

                    <div className="lg:w-[20%] xxxs:w[100%] lg:mt-10">
                        <h1 className="text-lg font-medium">OKDOO SA</h1>
                            <div className="flex items-center gap-5 mt-3">
                                <FaIdCard size={24}/> <h1>CHE-489.956.244</h1>
                            </div>
                            <div className="flex items-center gap-5 mt-3">
                                <IoCallSharp size={24}/> <h1>+41 767 226 266</h1>
                            </div>
                            <div className="flex items-center gap-5 mt-3">
                                <FaLocationDot size={35} className="self-start"/> <h1 className="xxxs:w-40">Chemin du Treizou 1 CH-1270 Trélex Switzerland</h1>
                            </div>
                    </div>
                </div>
                <div className="lg:px-40 md:px-20 sm:px-12 xxs:px-4 xxxs:px-5 lg:mt-8 md:mt-8 xxxs:mt-5">
                    <h1 className="font-medium text-zinc-400 font-abc lg:text-end md:text-start">{ t('last_part') }</h1>
                </div>
            </div>
        </>
     );
}

export default Footer;