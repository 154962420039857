import React from "react";
import Card from "../../components/common/Certifications/Card";
import card1 from "../../assets/card1.jpg";
import card2 from "../../assets/card2.jpg";
import card3 from "../../assets/card3.jpg";
import card4 from "../../assets/card4.jpg";
import card5 from "../../assets/card5.jpg";
import card6 from "../../assets/card6.webp";
import card7 from "../../assets/card7.webp";
import card8 from "../../assets/card8.webp";
import card9 from "../../assets/card9.webp";
import card10 from "../../assets/card10.webp";
import card11 from "../../assets/card11.webp";
import card12 from "../../assets/card12.webp";
import card13 from "../../assets/card13.webp";
import card14 from "../../assets/card14.webp";
import card15 from "../../assets/card15.webp";
import { useTranslation } from "react-i18next";
import "../../i18n"; 



function Section1() {
    
    const { t } = useTranslation();


    return ( 
        <>
            <div className="xl:px-40 lg:px-24 md:px-20  xxs:px-10 xxxs:px-8 mb-24">
                <h1 className="text-zinc-800 font-medium lg:text-3xl md:text-xl xxxs:text-lg font-abc lg:leading-8 md:leading-6  lg:tracking-normal md:tracking-wider xxxs:tracking-tigh xxxs:leading-5">{ t('center_part_1') }</h1>
            </div>
            <div className="xl:px-40 lg:px-24 mb-60 md:px-20  grid lg:grid-cols-5 md:grid-cols-2 gap-8">
                <Card 
                    imgSrc={card1} 
                    altText="Cloud Security Alliance Logo" 
                    title={ t('img_title_1') }
                    description={ t('img_des_1') } 
                />
                <Card 
                    imgSrc={card2} 
                    altText="CyberGRX Logo" 
                    title="CyberGRX" 
                    description={ t('img_des_2') } 
                />
                <Card 
                    imgSrc={card3} 
                    altText="CyberVadis Logo" 
                    title="CyberVadis" 
                    description={ t('img_des_3') } 
                />
                <Card 
                    imgSrc={card4} 
                    altText="ISO 14001 Logo" 
                    title={ t('img_title_4') } 
                    description={ t('img_des_4') } 
                />
                <Card 
                    imgSrc={card5}  
                    altText="ISO 9001 Logo" 
                    title="ISO 9001" 
                    description={ t('img_des_5') } 
                />
                <Card 
                    imgSrc={card6} 
                    altText="Cloud Security Alliance Logo" 
                    title="ISO 20000" 
                    description={ t('img_des_6') }
                />
                <Card 
                    imgSrc={card7} 
                    altText="CyberGRX Logo" 
                    title={ t('img_title_7') }
                    description={ t('img_des_7') } 
                />
                <Card 
                    imgSrc={card8} 
                    altText="CyberVadis Logo" 
                    title={ t('img_title_8') } 
                    description={ t('img_des_8') } 
                />
                <Card 
                    imgSrc={card9} 
                    altText="ISO 14001 Logo" 
                    title={ t('img_title_9') } 
                    description={ t('img_des_9') } 
                />
                <Card 
                    imgSrc={card10}  
                    altText="ISO 9001 Logo" 
                    title={ t('img_title_10') }
                    description={ t('img_des_10') }
                />
                <Card 
                    imgSrc={card11} 
                    altText="Cloud Security Alliance Logo" 
                    title={ t('img_title_11') }
                    description={ t('img_des_11') }
                />
                <Card 
                    imgSrc={card12} 
                    altText="CyberGRX Logo" 
                    title={ t('img_title_12') } 
                    description={ t('img_des_12') }
                />
                <Card 
                    imgSrc={card13} 
                    altText="CyberVadis Logo" 
                    title="SOC 1" 
                    description={ t('img_des_13') } 
                />
                <Card 
                    imgSrc={card14} 
                    altText="ISO 14001 Logo" 
                    title="SOC 2" 
                    description={ t('img_des_14') }
                />
                <Card 
                    imgSrc={card15}  
                    altText="ISO 9001 Logo" 
                    title="SOC 3" 
                    description={ t('img_des_15') }
                />
            </div>
        </>
     );
}

export default Section1;