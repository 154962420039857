import React from "react";
import FeatureCard from "../../components/common/Solution/FeatureCard";
import { GoChevronRight } from "react-icons/go";
import { useTranslation } from "react-i18next";
import "../../i18n"; 
import { useNavigate } from "react-router-dom";

function Section1() {

    const navigate = useNavigate();

    const contactus = () => {
        navigate('/Index4');
    };

    const { t } = useTranslation();

    return ( 
        <>
            <div className="xl:px-36 lg:px-32 md:px-24 sm:px-10 xxs:px-8 xxxs:px-10">
                <h1 className="text-center xl:tracking-wide xl:leading-tight xxxs:tracking-tight  xxxs:leading-tight text-zinc-800">{ t('solution_center_part_2') }</h1>
            </div>


            <div className="lg:px-36 md:px-20 sm:px-10 xxs:px-5 xxxs:px-8 flex lg:flex-nowrap xxxs:flex-wrap gap-10 font-abc lg:mt-20 xxxs:mt-14 text-zinc-800">
                <FeatureCard 
                    title={ t('solution_common_part_1') } 
                    content={ t('solution_common_part_des_1') }
                />
                <FeatureCard 
                    title={ t('solution_common_part_2') } 
                    content={ t('solution_common_part_des_2') }
                />
                <FeatureCard 
                    title={ t('solution_common_part_3') } 
                    content={ t('solution_common_part_des_3') }
                />
            </div>


            <div className="flex items-center justify-center py-20 cursor-pointer ">
            <div className="relative group">
                <div className="px-4 py-2 bg-amber-500 rounded-2xl text-xl text-white shadow-2xl flex items-center space-x-2 hover:border-4 hover:border-yellow-500" onClick={contactus}>
                    <span className="duration-200 group-hover:opacity-100 group-hover:translate-x-1">{ t('solution_part_button') }</span>
                    <GoChevronRight className="transform transition-transform duration-200 opacity-0 group-hover:opacity-100 group-hover:translate-x-1" />
                </div>
                <div className="absolute top-0 right-0 -mr-1 -mt-1 w-4 h-4 rounded-full bg-amber-600 animate-ping"></div>
                <div className="absolute top-0 right-0 -mr-1 -mt-1 w-4 h-4 rounded-full bg-amber-600"></div>
            </div>
        </div>
        </>
     );
}

export default Section1;