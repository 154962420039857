import React from "react";
import plan from "../../assets/plan.png";
import process from "../../assets/process.png";
import smily from "../../assets/smily.png";
import Section1 from "./Section1";
import Section2 from "./Section2";
import { useTranslation } from "react-i18next";
import "../../i18n"; 

function Index1() {

    const { t } = useTranslation();

    return ( 
        <>
            <div data-aos="zoom-in" className="index1  w-full pb-24">
                <div className="font-xyz text-white xl:ml-40 lg:ml-24 md:ml-7">
                        <h1 data-aos="slide-down" className="italic  font-medium text-shadow-three 2xl:text-6xl 2xl:pt-28 xl:pt-60 lg:text-5xl md:pt-32 md:text-5xl sm:pt-36 sm:text-4xl xxs:pt-14 xxs:text-3xl xxxs:pt-16 xxxs:text-4xl xxxs:ml-5">{ t('title1') }</h1>
                </div>
                <div className="mt-10  xl:ml-40 lg:ml-24 md:ml-7 xxxs:ml-5">
                    <div className="text-white font-abc">
                        <div className="flex gap-3 items-center xl:text-3xl lg:text-2xl md:text-2xl md:w-[80%] sm:text-3xl sm:w-[80%] xxxs:text-2xl xxs:w-[80%] ">
                            <img src={plan} alt="" />
                            <h1 className="text-shadow-four font-medium">{ t('title1_part_1') }</h1>
                        </div>
                        <div className="flex gap-7 items-center xl:text-3xl md:mt-5 xxxs:mt-6 md:text-2xl sm:text-3xl xxxs:text-2xl xxxs:w-[90%]">
                            <img src={process} alt="" />
                            <h1 className="text-shadow-four font-medium">{ t('title1_part_2') }</h1>
                        </div>
                        <div className="flex gap-5 items-center xl:text-3xl md:mt-5 xxxs:mt-6 md:text-2xl sm:text-3xl xxxs:text-2xl xxs:w-[80%]">
                            <img src={smily} alt="" />
                            <h1 className="text-shadow-four font-medium">{ t('title1_part_3') }</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="font-pqr bg-cyan-900 text-white text-center py-6 mb-24">
                    <h1 className="lg:text-3xl md:text-2xl xxxs:text-xl font-bold">{ t('whatwedo_centerpart') }</h1>
            </div>
            <Section1 />
            <Section2 />
        </>
     );
}

export default Index1;