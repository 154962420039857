import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../../i18n"; 
 

function Index4() {

    const { t } = useTranslation();

    const initialFormData = {
        organization: '',
        country: '',
        firstName: '',
        lastName: '',
        mobile: '',
        email: '',
        message: '',
        captchaInput: '',
    };

    const [formData, setFormData] = useState(initialFormData);
    const [errors, setErrors] = useState({});
    const [captcha, setCaptcha] = useState({
        question: '',
        answer: 0,
    });
    const [storedData, setStoredData] = useState(() => {
        const localStorageData = localStorage.getItem('formData');
        return localStorageData? JSON.parse(localStorageData) : [];
      });

    useEffect(() => {
        generateCaptcha();
    }, []);

    const generateCaptcha = () => {
        const num1 = Math.floor(Math.random() * 10);
        const num2 = Math.floor(Math.random() * 10);
        setCaptcha({
            question: `${num1} + ${num2}`,
            answer: num1 + num2,
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: '' });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const newErrors = validateForm();
    
        if (Object.keys(newErrors).length === 0) {
            if (parseInt(formData.captchaInput) === captcha.answer) {
                alert('Form submitted successfully!');
                setFormData(initialFormData);
                setErrors({});
                generateCaptcha();
    
                const newData = [...storedData, formData];
                localStorage.setItem('formData', JSON.stringify(newData));
                setStoredData(newData);
            } else {
                setErrors({ ...newErrors, captchaInput: 'Captcha is incorrect' });
                generateCaptcha();
            }
        } else {
            setErrors(newErrors);
        }
    };
    
    

    const validateForm = () => {
        const newErrors = {};
        if (!formData.organization) newErrors.organization = 'Organization is required';
        if (!formData.country) newErrors.country = 'Country is required';
        if (!formData.firstName) newErrors.firstName = 'First Name is required';
        if (!formData.lastName) newErrors.lastName = 'Last Name is required';
        if (!formData.mobile) newErrors.mobile = 'Mobile is required';
        else if (formData.mobile.toString().length !== 10) newErrors.mobile = 'Mobile number must be 10 digits';
        if (!formData.email) newErrors.email = 'Email Address is required';
        if (!formData.message) newErrors.message = 'Message is required';
        return newErrors;
    };


    return (
        <>
            <div data-aos="zoom-in"  className="index4 md:opacity-100 xxxs:opacity-40 w-full 2xl:h-[67vh] pb-56">
                <div className="font-xyz text-white xl:ml-40 lg:ml-24 md:ml-20 sm:ml-10 xxxs:ml-10">
                    <h1 data-aos="slide-down"  className="italic font-medium text-shadow-five lg:text-7xl md:text-5xl xxxs:text-4xl 2xl:pt-48 xl:pt-72 lg:pt-64 md:pt-48 sm:pt-11 xxxs:pt-11">{ t('title') }</h1>
                </div>
            </div>
            <div className="2xl:px-40 xl:px-36 lg:px-24 md:px-20 xxxs:px-10 flex lg:flex-nowrap xxxs:flex-wrap gap-16 mt-24">
            <div className="lg:w-[50%] md:w-[100%]">
            <form onSubmit={handleSubmit} className="space-y-4 font-abc text-sm">
                <div className="flex md:flex-nowrap xxxs:flex-wrap gap-4">
                    <div className="md:w-1/2 xxxs:w-full">
                        <input 
                            type="text" 
                            name="organization" 
                            placeholder={ t('placeholder1') } 
                            className={`w-full p-4 border rounded outline-none ${errors.organization ? 'border-red-500' : 'bg-gray-200'}`} 
                            value={formData.organization} 
                            onChange={handleChange}
                            onKeyPress={(event) => {
                                if (!/[a-zA-Z@.]/.test(event.key)) {
                                event.preventDefault();
                                }
                            }} 
                        />
                        {errors.organization && <span className="text-red-500">{errors.organization}</span>}
                    </div>
                    <div className="md:w-1/2 xxxs:w-full">
                        <input 
                            type="text" 
                            name="country" 
                            placeholder={ t('placeholder2') } 
                            className={`w-full p-4 border rounded outline-none ${errors.country ? 'border-red-500' : 'bg-gray-200'}`} 
                            value={formData.country} 
                            onChange={handleChange} 
                            onKeyPress={(event) => {
                                if (!/[a-zA-Z@.]/.test(event.key)) {
                                event.preventDefault();
                                }
                            }} 
                        />
                        {errors.country && <span className="text-red-500">{errors.country}</span>}
                    </div>
                </div>
                <div className="flex md:flex-nowrap xxxs:flex-wrap gap-4">
                    <div className="md:w-1/2 xxxs:w-full">
                        <input 
                            type="text" 
                            name="firstName" 
                            placeholder={ t('placeholder3') }
                            className={`w-full p-4 border rounded outline-none ${errors.firstName ? 'border-red-500' : 'bg-gray-200'}`} 
                            value={formData.firstName} 
                            onChange={handleChange}
                            onKeyPress={(event) => {
                                if (!/[a-zA-Z@.]/.test(event.key)) {
                                event.preventDefault();
                                }
                            }}  
                        />
                        {errors.firstName && <span className="text-red-500">{errors.firstName}</span>}
                    </div>
                    <div className="md:w-1/2 xxxs:w-full">
                        <input 
                            type="text" 
                            name="lastName" 
                            placeholder={ t('placeholder4') } 
                            className={`w-full p-4 border rounded outline-none ${errors.lastName ? 'border-red-500' : 'bg-gray-200'}`} 
                            value={formData.lastName} 
                            onChange={handleChange} 
                            onKeyPress={(event) => {
                                if (!/[a-zA-Z@.]/.test(event.key)) {
                                event.preventDefault();
                                }
                            }} 
                        />
                        {errors.lastName && <span className="text-red-500">{errors.lastName}</span>}
                    </div>
                </div>
                <div className="flex md:flex-nowrap xxxs:flex-wrap gap-4">
                    <div className="md:w-1/2 xxxs:w-full">
                        <input 
                            type="number" 
                            name="mobile" 
                            placeholder={ t('placeholder5') }
                            className={`w-full p-4 border rounded outline-none ${errors.mobile ? 'border-red-500' : 'bg-gray-200'}`} 
                            value={formData.mobile} 
                            onChange={handleChange} 
                        />
                        {errors.mobile && <span className="text-red-500">{errors.mobile}</span>}
                    </div>
                    <div className="md:w-1/2 xxxs:w-full">
                        <input 
                            type="email" 
                            name="email" 
                            placeholder={ t('placeholder6') } 
                            className={`w-full p-4 border rounded outline-none ${errors.email ? 'border-red-500' : 'bg-gray-200'}`} 
                            value={formData.email} 
                            onChange={handleChange} 
                            onKeyPress={(event) => {
                                if (!/[a-zA-Z@.0-9]/.test(event.key)) {
                                event.preventDefault();
                                }
                            }} 
                        />
                        {errors.email && <span className="text-red-500">{errors.email}</span>}
                    </div>
                </div>
                <div>
                    <textarea 
                        name="message" 
                        placeholder="Message" 
                        className={`w-full p-4 border rounded h-40 outline-none ${errors.message ? 'border-red-500' : 'bg-gray-200'}`} 
                        value={formData.message} 
                        onChange={handleChange}
                        onKeyPress={(event) => {
                                if (!/[a-zA-Z@.]/.test(event.key)) {
                                event.preventDefault();
                                }
                            }} 
                    ></textarea>
                    {errors.message && <span className="text-red-500">{errors.message}</span>}
                </div>
                <div className="flex justify-end items-center gap-2">
                    <span>{captcha.question} =</span>
                    <input 
                        type="text" 
                        name="captchaInput" 
                        value={formData.captchaInput} 
                        onChange={handleChange} 
                        className={`w-12 p-4 border rounded outline-none ${errors.captchaInput ? 'border-red-500' : 'bg-gray-200'}`} 
                    />
                <div className="flex items-center justify-center ">
                    <button type="submit" className="bg-gray-400 group relative inline-flex items-center overflow-hidden outline-none rounded-2xl px-8 py-3 transition">
                        <div className="absolute inset-0 flex items-center [container-type:inline-size]">
                        <div className="absolute h-[100cqw] w-[100cqw] animate-spin bg-[conic-gradient(from_0_at_50%_50%,rgba(255,255,255,0.5)_0deg,transparent_60deg,transparent_300deg,rgba(255,255,255,0.5)_360deg)] opacity-0 transition duration-300 group-hover:opacity-100" />
                        </div>
                        <div className="absolute inset-0.5 rounded-full " />
                        <div className="absolute bottom-0 left-1/2 h-1/3 w-4/5 -translate-x-1/2 rounded-full bg-white/10 opacity-50 blur-md transition-all duration-500 group-hover:h-2/3 group-hover:opacity-100" />
                        <div className="relative inline-flex items-center gap-2">
                        <span className="font-mona mt-px bg-gradient-to-b from-white/25 to-white bg-clip-text text-lg font-medium text-transparent transition-all duration-200">{ t('button1') }</span>
                        </div>  
                    </button>
                </div>


                </div>
                {errors.captchaInput && <span className="text-red-500">{errors.captchaInput}</span>}
            </form>
        </div>

                <div className="lg:w-[50%] md:w-[100%] font-abc text-stone-500 tracking-wide">
                    <h1 className="text-justify text-xl font-medium  xl:leading-6 xl:tracking-normal xxxs:leading-6 xxxs:tracking-tight">
                    { t('para1') } 
                    </h1>
                    <h1 className="font-extrabold text-xl mt-4">
                    { t('name1') } 
                    </h1>
                    <p className="text-xl font-medium ml-10 mt-4">
                    +41 767 226 266
                    </p>
                    <h1 className="font-extrabold text-xl mt-8">
                    { t('name2') }

                    </h1>
                    <p className="text-xl font-medium ml-10 mt-4 w-52 xl:leading-6 xl:tracking-normal  lg:tracking-tight xxxs:leading-6">
                    { t('address') }

                    </p>
                    <h1 className="font-medium text-xl mt-14">
                    { t('thanks') }
                    </h1>
                </div>
            </div>
        </>
    );
}

export default Index4;
