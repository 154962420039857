import React from "react";
import capbook from "../../assets/capbook.png";
import meter from "../../assets/meter.png";
import chart from "../../assets/chart.png";
import Section1 from "./Section1";
import Section2 from "./Section2";
import { useTranslation } from "react-i18next";
import "../../i18n"; 

function Index2() {

    const { t } = useTranslation();


    return ( 
        <>
            <div data-aos="zoom-in" className="index2 md:opacity-100 xxxs:opacity-40 w-full 2xl:h-[85vh] pb-24">
                <div className="font-xyz text-white xl:ml-40 md:ml-20 xxs:ml-10 xxxs:ml-8">
                        <h1 data-aos="zoom-in" className="italic  font-medium text-shadow-five lg:text-7xl xxxs:text-4xl 2xl:pt-32 xl:pt-44 lg:pt-32 sm:pt-28 xxs:pt-28 xxxs:pt-24">{ t('title2') }</h1>
                </div>
                <div className="2xl:mt-10 xl:mt-16 xl:ml-40 lg:ml-20 lg:mt-10 md:ml-20 xxxs:mt-10 xxs:ml-10 xxxs:ml-8">
                    <div className="text-white font-xyz">
                        <div className="flex lg:gap-6 xxs:gap-4 xxxs:gap-3 items-center text-3xl tracking-wide lg:mb-5 md:mb-4 sm:mb-3  ">
                            <img src={capbook} alt=""/>
                            <h1 className="text-shadow-six">Flexible</h1>
                        </div>
                        <div className="ml-20 text-shadow-six mb-8 tracking-widest">
                            <p>{ t('sub_title_des_1/1') } </p> 
                            <p className="w-[80%]">{ t('sub_title_des_1/2') }</p>
                        </div>
                        <div className="flex lg:gap-7 xxs:gap-5 xxxs:gap-4 items-center text-3xl  lg:mb-3 md:mb-4 sm:mb-4 tracking-wide">
                            <img src={meter} alt=""/>
                            <h1 className="text-shadow-six">{ t('sub_title2') }</h1>
                        </div>
                        <div className="ml-20 text-shadow-six mb-8 tracking-widest">
                            <p className="w-[80%]">{ t('sub_title_des_2') }</p> 
                        </div>
                        <div className="flex lg:gap-6 xxs:gap-5 xxxs:gap-4 items-center text-3xl lg:mb-5 md:mb-4 sm:mb-3 tracking-wide">
                            <img src={chart} alt=""/>
                            <h1 className="text-shadow-six">{ t('sub_title3') }</h1>
                        </div>
                        <div className="ml-20 text-shadow-six tracking-widest">
                            <p className="w-[80%]">{ t('sub_title_des_3') }</p> 
                        </div>
                    </div>
                </div>
            </div>
            <div className="font-pqr bg-amber-600 text-white text-center py-6 mb-24">
                    <h1 className="lg:text-3xl md:text-2xl xxs:text-lg xxxs:text-xl  font-bold xl:px-32 md:px-20 sm:px-6 xxs:px-0 xxxs:px-8">{ t('solution_center_part_1') }</h1>
            </div>
            <Section1 />
            <Section2 />
        </>
     );
}

export default Index2;