// src/components/FAQItem.js
import React, { useRef } from 'react';

const FAQItem = ({ id, title, children, selected, setSelected }) => {
  const contentRef = useRef(null);

  const handleClick = () => {
    setSelected(selected !== id ? id : null);
  };

  const backgroundColor = id % 2 === 0? 'bg-white' : 'bg-slate-300';

  return (
    <li className={`relative border-b border-gray-200 ${backgroundColor}`}>
      <button
        type="button"
        className="w-full px-6 py-4 text-left"
        onClick={handleClick}
        
      >
        <div className="flex items-center justify-between">
          <span className='font-abc md:text-lg xxxs:text-base text-slate-700 font-light'>{title}</span>
          <svg
            className={`w-5 h-5 text-gray-500 transition-transform ${
              selected === id ? 'transform rotate-180' : ''
            }`}
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M19 9l-7 7-7-7"></path>
          </svg>
        </div>
      </button>
      <div
        className="relative overflow-hidden transition-all max-h-0 duration-700"
        style={{
          maxHeight: selected === id ? contentRef.current.scrollHeight : 0,
        }}
        ref={contentRef}
      >
        <div className="px-6 pb-6 font-hij text-stone-500 text-sm leading-6">{children}</div>
      </div>
    </li>
  );
};

export default FAQItem;
