import React from 'react';

const Card = ({ number, title, description }) => {
  return (
    <div className="lg:w-[33%] md:w-[100%] ">
      <p className="font-lmn lg:text-lg md:text-sm xxs:text-sm">{number}</p>
      <h1 className="font-xyz lg:text-2xl md:text-xl  xxxs:text-sm text-slate-950 mt-5 ">{title}</h1>
      <p className="font-lmn xxxs:text-md  mt-5 sm:leading-normal xxxs:leading-relaxed">{description}</p>
    </div>
  );
};

export default Card;