import React from "react";
import Workflow from "../../assets/Workflow.jpg"
import { useTranslation } from "react-i18next";
import "../../i18n"; 

function Section1() {

    const { t } = useTranslation();

    return ( 
        <>
            <div className="flex lg:flex-nowrap xxxs:flex-wrap gap-16  xl:px-40 lg:px-24 md:px-20 sm:px-11 xxs:px-10 xxxs:px-8">
                <div className="lg:w-[60%] md:w-[100%]">
                    <img src={Workflow} alt="" />
                </div>
                <div className="lg:w-[40%] md:w-[100%]">
                    <h1 className="font-abc text-2xl font-medium leading-6 ">{ t('whatwedo_part1_main_title') }</h1>
                    <p className="text-justify leading-6 text-zinc-500 lg:text-base xxxs:text-sm mt-2">{ t('whatwedo_part1_main_title1_des') }</p>
                    <h1 className="text-amber-500 mt-2 text-xl">{ t('whatwedo_part1_title1') }</h1>
                    <p className="text-justify leading-6 text-zinc-500 lg:text-base xxxs:text-sm mt-2">{ t('whatwedo_part1_title1_des') }</p>
                    <h1 className="text-amber-500 mt-2 text-xl">{ t('whatwedo_part1_title2') }</h1>
                    <p className="text-justify leading-6 text-zinc-500 lg:text-base xxxs:text-sm mt-2">{ t('whatwedo_part1_title2_des') }</p>
                    <h1 className="text-amber-500 mt-2 text-xl">{ t('whatwedo_part1_title3') }</h1>
                    <p className="text-justify leading-6 text-zinc-500 lg:text-base xxxs:text-sm mt-2">{ t('whatwedo_part1_title3_des') }</p>
                    <h1 className="text-amber-500 mt-2 text-xl">{ t('whatwedo_part1_title4') }</h1>
                    <p className="text-justify leading-6 text-zinc-500 lg:text-base xxxs:text-sm mt-2">{ t('whatwedo_part1_title4_des') }</p>
                </div>
            </div>
            <div className="font-pqr text-white bg-amber-600 text-center py-6 mt-24 mb-16">
                    <h1 className="lg:text-3xl md:text-2xl xxs:text-xl font-bold">{ t('whatwedo_centerpart2') }</h1>
            </div>
        </>
     );
}

export default Section1;