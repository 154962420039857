import React from "react";

function Card({ imgSrc, altText, title, description }) {
    return ( 
        <>
            <div className="flex flex-col items-center text-center pb-20">
                <img src={imgSrc} alt={altText} className="xl:w-48 xl:h-48 lg:w-32 lg:h-32 md:w-80 sm:w-80 xxs:w-72 xxxs:w-64  mb-4" />
                <p className="text-lg w-full text-zinc-800 mb-2 text-center ">{title}</p>
                <p className="text-zinc-600 w-full tracking-tight text-center md:px-0 xxxs:px-14">{description}</p>  
            </div>
        </>
     );
}

export default Card;